
import React, { useState } from "react";
import "./header.css";
import Mobile from "./mobile/mobile";
import Web from "./web/web";
function Header() {
	// USE STATE HOOK - this is our hook!
	// give it a variable name, setter function
	// false is our initial condition/value
	// only when isOpen is true is when our mobile is visible!
	const [isOpen, setIsOpen] = useState(false);

	const handleLogoClick = () => {
		window.scrollTo(0, 0);
	};

	window.onscroll = function () {
		setIsOpen(false);
	};

	return (
		<div className="header">
			<div onClick={handleLogoClick} className="logo">
				Daniel Zhang
			</div>
			<div className="menu">
				<div className="web-menu">
					<Web />
				</div>
				<div className="mobile-menu">
					<div onClick={() => setIsOpen(!isOpen)}>
						<img src="https://img.icons8.com/material-outlined/24/000000/menu--v1.png"/>
					</div>
					{isOpen && <Mobile isOpen={isOpen} setIsOpen={setIsOpen} />}
				</div>
			</div>
		</div>
	);
}

export default Header;


   // USE STATE HOOK - this is our hook!
	// give it a variable name, setter function
	// false is our initial condition/value
	// only when isOpen is true is when our mobile is visible!