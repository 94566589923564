import React from 'react'
import './web.css'

function Web() {
    return (
        <div className="web">
            <div className="web-option">
                <a href='#work'>
                    <img src="https://img.icons8.com/material-outlined/24/000000/briefcase.png"/>
                    Work
                </a>
            </div>
            <div className="web-option">
                <a href='#projects'>
                    <img src="https://img.icons8.com/material-outlined/24/000000/group-of-projects.png"/>
                    Projects
                </a>
            </div>
            <div className="web-option">
                <a href='#skills'>
                    <img src="https://img.icons8.com/material-outlined/24/000000/laptop.png"/>
                    Skills
                </a>
            </div>
            <div className="web-option">
                <a href='#contact'>
                    <img src="https://img.icons8.com/material-outlined/24/000000/share-2.png"/>
                    Connect
                </a>
            </div>
        </div>
    )
}

export default Web
