export const WorkData = [
    {
        company: "Tangerine",
        designation: "Software Developer Intern",
        dateJoining: "05-2023 ",
        dateEnd: " 08-2023",
        companyLogo:
            "https://download.logo.wine/logo/Tangerine_Bank/Tangerine_Bank-Logo.wine.png",
        work: "Upgraded and modernized credit card APIs, broadening functionality and creating features for Tangerine's Kotlin-based backend-for-frontend structure.",
    },
    {
        company: "Amason",
        designation: "Software Development Engineer Intern",
        dateJoining: "05-2022 ",
        dateEnd: " 05-2023",
        companyLogo:
            "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Amazon_logo.svg/2560px-Amazon_logo.svg.png",
        work: "Created a React frontend portal for onboarding internal projects. \n Developed a scalable & responsive database & API for storage of financial transactions.",
    },
    {
        company: "Ceridian",
        designation: "Full Stack Software Developer Intern",
        dateJoining: "05-2021 ",
        dateEnd: " 08-2021",
        companyLogo:
            "https://cdn.technologyadvice.com/wp-content/uploads/2014/05/Ceridian-Lockup-Large-Andrew-Bowden.jpg",
        work: "Enhanced Dayforce, Ceridian's Flagship HCM software in a full-stack environment with Dojo, Typescript, React, C#, SQL and more.",
    },
    {
        company: "Ceridian",
        designation: "Software Engineer in Test Intern",
        dateJoining: "05-2020 ",
        dateEnd: " 08-2020",
        companyLogo:
            "https://cdn.technologyadvice.com/wp-content/uploads/2014/05/Ceridian-Lockup-Large-Andrew-Bowden.jpg",
        work: "Enhanced and maintained a UI Automation test framework used by 30+ TestEngineers & QA analysts through creation of reusable code modules using C#, Selenium Webdriver, & Fitnesse.",
    },
    {
        company: "Service Canada",
        designation: "Summer Student Intern",
        dateJoining: "07-2019 ",
        dateEnd: " 08-2019",
        companyLogo:
            "https://i1.wp.com/silentvoice.ca/wp-content/uploads/2017/04/service-canada-logo.png?ssl=1",
        work: "Maintained and updated databases & tracking systems containing data for 5+ million clients",
    },
];