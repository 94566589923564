import React from "react";
import "./contact.css";
import SocialContact from "../../common/social-contact/socialContact";
import Separator from "../../common/separator/separator";

function Contact() {
	return (
		<div className="contact">
			<Separator />
			<label className="section-title">Connect</label>
			<div className="contact-container">
				<div className="contact-left">
					<p>Want to connect? Contact me here on any of these platforms!</p>
					<SocialContact />
				</div>
				<div className="download">
					<a href="https://drive.google.com/file/d/10LhUmuMBi0BBT4vuFNE97wLL4bjpm466/view?usp=sharing">
						<img src="https://img.icons8.com/material-outlined/24/000000/resume.png"/>
						Resume
					</a>
				</div>
			</div>
		</div>
	);
}

export default Contact;