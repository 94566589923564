import React from 'react'
import SocialContact from '../../common/social-contact/socialContact'
import './about.css'

function About() {
    return (
        <div className='about'> 
            <div className='about-top'>
                <div className='about-info'>
                    Hi! 👋 I'm
                    <br/>
                    <span className='info-name'>
                        Daniel Zhang
                    </span>
                    .
                    <br/>
                    I'm a fourth year computer engineering student at the University of Toronto! I love to program and I'm an avid learner of any new technologies. I've interned at places like Amazon and Tangerine, and am currently seeking a new-grad role!

                </div>
                <div className='about-photo'>
                    <img 
                        src={require('../../../assets/me.jpg').default} 
                        className='picture'
                    />
                </div>
            </div>
            <SocialContact/>
        </div>
    )
}

export default About;
