import React from "react";
import "./mobile.css";

function Mobile({ isOpen, setIsOpen }) {
	return (
		<div className="mobile">
			<div onClick={() => setIsOpen(!isOpen)} className="close-icon">
				<img src="https://img.icons8.com/windows/32/000000/macos-close.png"/>
			</div>
			<div className="mobile-options">
				<div className="mobile-option">
					<a href="#work">
						{" "}
						<img src="https://img.icons8.com/material-outlined/24/000000/briefcase.png"/>
						Work
					</a>
				</div>
				<div className="mobile-option">
					<a href="#projects">
                    	<img src="https://img.icons8.com/material-outlined/24/000000/group-of-projects.png"/>
						Projects
					</a>
				</div>
				<div className="mobile-option">
					<a href="#skills">
                    	<img src="https://img.icons8.com/material-outlined/24/000000/laptop.png"/>
						Skills
					</a>
				</div>
				<div className="mobile-option">
					<a href="#contact">
                    	<img src="https://img.icons8.com/material-outlined/24/000000/share-2.png"/>
						Connect
					</a>
				</div>
			</div>
		</div>
	);
}

export default Mobile;