import React from 'react'
import "./footer.css"

function Footer() {
    return (
        <div className="footer">
            <a href="https://storyset.com/people">Storyset Illustration </a>
            <img src="https://img.icons8.com/emoji/48/000000/fire.png"/>
            <b>Built with React!</b>
            <img src="https://img.icons8.com/emoji/48/000000/fire.png"/>
            <a href="https://icons8.com/"> Icons8 Icons </a>
        </div>
    )
}

export default Footer
