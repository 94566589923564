import React from "react";
import "./work-card.css";
function WorkCard({ item }) {
    return (
        <div className="work-card">
            <div className="work-div-logo">
                <img src={item.companyLogo} className="work-logo" />
            </div>
            <div className="work-info">
                <label className="company-name">
                    {item.company}
                </label>
                <label className="work-title">
                    {item.designation}
                </label>
                <div className="work-dates">
                    <label>
                        {item.dateJoining}
                    </label>
                    -
                    <label>
                        {item.dateEnd}
                    </label>
                </div>
                <div className="work-desc">
                    <p>{item.work}</p>
                </div>
            </div>
        </div>
    );
    }

export default WorkCard;