export const ProjectData = [
    {
        id: 1,
        title: "ImageRepo",
        about:
            "ImageRepo - a simple but robust image repository system with a twist! Features functions such as adding/deleting images as well as the ability to buy & sell images, as well as set prices! Built as an exercise to develop my ability as a full-stack engineer.",
        tags: ["Python", "Flask", "SQLAlchemy", "PostgreSQL", "JavaScript", "HTML/CSS", "React", "RESTful API", "Full Stack"],
        github: "https://github.com/DanielZhangD/image-repo",
        image: require('../assets/icons/ImageRepo.png').default,
    },
    {
        id: 2,
        title: "DaRKMaps",
        about:
            "Welcome to DaRKMaps, a student-friendly GIS displaying important information for various cities across the globe. Built with C++, DaRKMaps utilizes GTK to render our maps and retrieves data from APIs such as the OpenStreetMap Database API for a smooth user experience.",
        tags: ["C++", "Mapping"],
        github: "https://github.com/DanielZhangD/ECE297---DaRKMaps",
        image: require('../assets/icons/DaRKMaps.png').default,

    },

    {
        id: 3,
        title: "Asteroids",
        about:
            "A remake of the classic Asteroids game, using Java. This game includes Asteroids, enemy CPU, Powerups, Lives, Scoreboard, and more!",
        tags: [
            "Java"
        ],
        github: "https://github.com/DanielZhangD/Asteroids",
        image: require('../assets/icons/Asteroids.png').default,
    },
];