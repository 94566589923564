export const SocialData=[
    {
        platform:"Github",
        link:"https://github.com/DanielZhangD",
        icon: require('../assets/icons/github.png').default,
    },
    {
        platform:"Linkedin",
        link:"https://www.linkedin.com/in/daniel-d-zhang/",
        icon: require('../assets/icons/linkedin.png').default,
    },
]