export const SkillsData = [
	{
		type: "Frontend",
		list: [
			{
				name: "ReactJS",
				icon: <i class="devicon-react-original colored"></i>,
			},
			{
				name: "Javascript",
				icon: <i class="devicon-javascript-plain colored"></i>,
			},
			{
				name: "Typescript",
				icon: <i class="devicon-typescript-plain colored"></i>,
			},
			{
				name: "CSS",
				icon: <i class="devicon-css3-plain-wordmark colored"></i>,
			},
			{
				name: "SASS",
				icon: <i class="devicon-sass-original colored"></i>,
			},
			{
				name: "HTML",
				icon: <i class="devicon-html5-plain colored"></i>,
			},
		],
	},
	{
		type: "Backend",
		list: [
			{
				name: "C/C#/C++",
				icon: <i class="devicon-cplusplus-plain colored"></i>,
			},
			{
				name: "Python",
				icon: <i class="devicon-python-plain colored"></i>,
			},
			{
				name: "Java",
				icon: <i class="devicon-java-plain colored"></i>,
			},
			{
				name: "PostgreSQL",
				icon: <i class="devicon-postgresql-plain colored"></i>,
			},
			{
				name: "NodeJS",
				icon: <i class="devicon-nodejs-plain colored"></i>,
			},
			{
				name: "Flask",
				icon: <i class="devicon-flask-plain colored"></i>,
			},
			{
				name: "Firebase",
				icon: <i class="devicon-firebase-plain colored"></i>,
			},

		],
	},
];